/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './OrderExperience.module.css';
import Rating1SVG from './imgs/smileRating1.svg';
import Rating2SVG from './imgs/smileRating2.svg';
import Rating3SVG from './imgs/smileRating3.svg';
import Rating4SVG from './imgs/smileRating4.svg';
import Rating5SVG from './imgs/smileRating5.svg';
import Rating1ActiveSVG from './imgs/smileRating1Active.svg';
import Rating2ActiveSVG from './imgs/smileRating2Active.svg';
import Rating3ActiveSVG from './imgs/smileRating3Active.svg';
import Rating4ActiveSVG from './imgs/smileRating4Active.svg';
import Rating5ActiveSVG from './imgs/smileRating5Active.svg';

class SmileRating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: null,
    }
  }

  componentDidMount() {
    const { rating } = this.props;
    this.setState({
      rating,
    });
  }

  componentDidUpdate(prevProps) {
    const { rating } = this.props;
    if (rating !== prevProps.rating) {
      this.setState({
        rating,
      });
    }
  }

  setRating = (rating) => {
    const { onRatingChange } = this.props;
    this.setState({
      rating,
    });

    onRatingChange(rating);
  }

  render() {
    const { rating } = this.state;

    return (
      <div className={[styles.smileRatingContainer, `dark:bg-dark`].join(' ')}>
        <div>
          <img
            className='dark:image-negative'
            src={rating === 1 ? Rating1ActiveSVG : Rating1SVG}
            alt="Rating 1"
            onClick={() => this.setRating(1)}
          />
        </div>
        <div>
          <img
            className='dark:image-negative'
            src={rating === 2 ? Rating2ActiveSVG : Rating2SVG}
            alt="Rating 2"
            onClick={() => this.setRating(2)}
          />
        </div>
        <div>
          <img
            className='dark:image-negative'
            src={rating === 3 ? Rating3ActiveSVG : Rating3SVG}
            alt="Rating 3"
            onClick={() => this.setRating(3)}
          />
        </div>
        <div>
          <img
            className='dark:image-negative'
            src={rating === 4 ? Rating4ActiveSVG : Rating4SVG}
            alt="Rating 4"
            onClick={() => this.setRating(4)}
          />
        </div>
        <div>
          <img
            className='dark:image-negative'
            src={rating === 5 ? Rating5ActiveSVG : Rating5SVG}
            alt="Rating 5"
            onClick={() => this.setRating(5)}
          />
        </div>
      </div>
    );
  }
}

SmileRating.propTypes = {
  rating: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  onRatingChange: PropTypes.func,
};

SmileRating.defaultProps = {
  rating: null,
  onRatingChange: () => {},
};

export default SmileRating;
