/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.css';

class SimpleModal extends Component {
  render() {
    const { children } = this.props;

    return (
      <div
        className={`${styles.modalWrapper} fixed inset-0 bg-opacity-25
        backdrop-blur-sm flex justify-center z-[99999]
        bg-darkBlack`}
      >
        <div
          id="simpleModal"
          className={`
            ${styles.modalContainer}
            bg-white dark:bg-dark  m-auto flex flex-col outline-none`}
        >
          { children }
        </div>
      </div>
    );
  }
}

SimpleModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default SimpleModal;
