/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-empty */
import React, { Component } from 'react';
import _ from 'underscore';
import styles from '../Contact.module.css';
import * as storeRepo from '../../../shared/repos/graphql/store';
import InputSearchBox from '../../../components/InputSearchBox/InputSearchBox';

class StoreField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: []
    };
  }

  componentDidMount() {
    this.getStores();
  }

  async getStores() {
    try {
      const response = await storeRepo.regularStores();

      const stores = _.pluck(response.data.regularStores, "name").sort();

      this.setState({
        stores
      });
    } catch (exception) {}
  }

  setStore = (store) => {
    const { component } = this.props;
    component.setState({
      store
    });
  };

  render() {
    const { component } = this.props;
    const { store } = component.state;
    const { stores } = this.state;
    const storesList = stores.map(item => {
      return {
        label: item,
        value: item
      };
    });
    return (
      <div className={styles.dropDownWrapper}>
        <InputSearchBox
          label="Bakery"
          items={storesList}
          onSelectItem={this.setStore}
          selectedItem={store}
        />
      </div>
    );
  }
}

export default StoreField;
