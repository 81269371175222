/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as userActions from '../../redux/actions/user';
import { connect } from 'react-redux';
import SimpleModal from '../Modal/SimpleModal';
import SmileRatingWidget from './SmileRatingWidget';
import ExitIconSVG from '../Modal/imgs/exit-icon.svg';
import { deliveryStatuses, orderExperienceSources } from '../../shared/constants/order';
import modalStyles from '../Modal/Modal.module.css';
import widgetStyles from '../OrderDetailsWidgets/OrderDetailsWidget.module.css';
import * as elementsActions from "../../redux/actions/elements";
import { getOrderExperiences } from '../../shared/repos/graphql/orderExperience';
import { getOrderExperienceBySource, isOrderExpired } from '../../shared/utilities/common';
import log from '../../shared/utilities/datadog/log';

class PostOrderExperienceModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderCodeForRate: null,
    }
  }

  componentDidMount() {
    this.init();
  };

  async checkOrder(orderCode) {
    const { setLastCompletedOrderCode } = this.props;
    try {
      const response = await getOrderExperiences(orderCode);
      const { order } = response.data;
      const isOrderCompleted = order?.status?.status >= deliveryStatuses.DRIVER_RETURNED;
      const isOrderExpiredVal = isOrderExpired(order, 30, 'days');
      const isAlreadyRated = getOrderExperienceBySource(order, orderExperienceSources.APP_LAUNCH_MODAL)
        || getOrderExperienceBySource(order, orderExperienceSources.CONFIRMATION_PAGE);
      const isOrderValidForRate = isOrderCompleted && !isOrderExpiredVal && !isAlreadyRated;
      if (isOrderValidForRate) {
        this.setState({
          orderCodeForRate: orderCode,
        });
        setLastCompletedOrderCode(null);
      } else if (isAlreadyRated || isOrderExpiredVal) {
        this.setState({
          orderCodeForRate: null,
        });
        setLastCompletedOrderCode(null);
      }
    } catch (error) {
      log.logError('[PostOrderExperienceModal][checkOrder] Error when check an order for rating modal', {
        orderCode,
        error,
      });
      this.setState({
        orderCodeForRate: null,
      });
      setLastCompletedOrderCode(null);
    }
    return;
  };

  onRatingModalClose = () => {
    const { setLastCompletedOrderCode } = this.props;
    this.setState({
      orderCodeForRate: null,
    });
    setLastCompletedOrderCode(null);
  }

  onOrderExperienceRatingSubmited = () => {
    const { setModalObject, setLastCompletedOrderCode } = this.props;
    this.setState({
      orderCodeForRate: null,
    });
    setLastCompletedOrderCode(null);
    setModalObject({
      title: 'Thank you for your feedback!',
      description: 'Your suggestions help us improve the experience for all Insomniacs.',
    });
  }

  init() {
    const { lastCompletedOrderCode } = this.props;
    if (lastCompletedOrderCode) {
      this.checkOrder(lastCompletedOrderCode);
    } else {
      this.setState({
        orderCodeForRate: null,
      });
    }
  };

  render() {
    const { orderCodeForRate } = this.state;
    return orderCodeForRate ? (
      <SimpleModal>
        <div className={[
          modalStyles.simpleModalContainer,
          'dark:bg-dark',
        ].join(' ')}>
          <div className={[
            widgetStyles.orderDetailsLine,
            widgetStyles.orderDetailsLineTitle,
            widgetStyles.borderBottom
          ].join(" ")}>
            <div className={[widgetStyles.contentCell, 'text-dark dark:text-white'].join(' ')}>
              <div className={modalStyles.rateModalTitle}>Rate your experience</div>
              <div className={modalStyles.rateModalSubTitle}>
                How was your experience with Insomnia?
              </div>
            </div>
            <div className={modalStyles.closeWrapper}>
              <img className={`dark:image-negative`} onClick={this.onRatingModalClose} src={ExitIconSVG} alt="Close" />
            </div>
          </div>
        </div>
        <SmileRatingWidget
          orderCode={orderCodeForRate}
          className={modalStyles.simpleModalContainer}
          source={orderExperienceSources.APP_LAUNCH_MODAL}
          onSubmited={this.onOrderExperienceRatingSubmited}/>
      </SimpleModal>
    ) : null;
  }
}

PostOrderExperienceModal.propTypes = {
  setModalObject: PropTypes.func,
  setLastCompletedOrderCode: PropTypes.func,
  lastCompletedOrderCode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
};

PostOrderExperienceModal.defaultProps = {
  setLastCompletedOrderCode: () => {},
  setModalObject: () => {},
  lastCompletedOrderCode: null,
};

export const mapStateToProps = (state) => {
  const { lastCompletedOrderCode } = state.user;
  return {
    lastCompletedOrderCode,
  };
};

export const mapDispatchToProps = (dispatch) => {
  const setLastCompletedOrderCode = (value) => dispatch(userActions.setLastCompletedOrderCode(value));
  const setModalObject = (value) => dispatch(elementsActions.setModalObject(value));
  return {
    setLastCompletedOrderCode,
    setModalObject,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostOrderExperienceModal);
