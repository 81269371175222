import React from 'react';

// REDUX
import { useSelector } from 'react-redux';
import { store } from '../../redux/store';
import * as elementsActions from '../../redux/actions/elements';

import * as modals from '../../shared/utilities/modals';
import * as orderExperienceRepo from '../../shared/repos/graphql/orderExperience';

import StarRating from './StarRating';
import Comment from './Comment';

import styles from './OrderExperience.css';
import { orderExperienceSources } from '../../shared/constants/order';


const OrderExperience = () => {

  const rating = useSelector((state) => state.elements.orderExperienceRating)
  const order = useSelector((state) => state.order)
  const submitted = useSelector((state) => state.elements.orderExperienceSubmitted)
  const display = submitted ? 'display-none' : 'orderExperienceContainer'
  const positiveRatingMin = 4;
  const orderExperienceText = 'Rate Your Ordering Experience';

  const clickHandler = () => {
    if (rating === 0) {
      return;
    }

    if (rating >= positiveRatingMin) {
      orderExperienceRepo.submitOrderExperience(rating, "", order.code, orderExperienceSources.CONFIRMATION_PAGE, []);
      store.dispatch(elementsActions.setModalObject(
        modals.orderExperienceFeedbackModalPositiveReivew()
      ));
    } else {
      orderExperienceRepo.submitOrderExperience(rating, "", order.code, orderExperienceSources.CONFIRMATION_PAGE, []);
      store.dispatch(elementsActions.setModalObject({
        children: <Comment />,
      }
      ));
    }
    store.dispatch(elementsActions.submittedOrderExperience(true));
  }

  return (
    <div className={display}>
      <span id="orderExperienceText" className={styles.text}>{orderExperienceText}</span>
      <StarRating />
      <button
        className='experienceSubmit bg-button font-filsonProMedium px-8 text-light my-4 h-[44px] lg:h-[54px] rounded-[54px]'
        type='button'
        onClick={clickHandler}
      >
        Submit
      </button>
    </div>
  );
}

export default OrderExperience;
