import gql from 'graphql-tag';

export const SUBMIT_ORDER_EXPERIENCE = gql`
  mutation SUBMIT_ORDER_EXPERIENCE($rating: Int!, $comment: String!, $orderCode: String!, $source: String, $metaTagIds: [Int!]) {
    submitOrderExperience(data: {rating: $rating, comment: $comment, orderCode: $orderCode, source: $source, metaTagIds: $metaTagIds} )
  }
`;

export const ORDER_EXPERIENCE_RATINGS = gql`
  query ORDER_EXPERIENCE_RATINGS($orderExperienceSource: String!, $orderCode: String) {
    orderExperienceRatings(orderExperienceSource: $orderExperienceSource, orderCode: $orderCode) {
      rating
      source
      metaTags {
        id
        title
      }
    }
  }
`;

export const ORDER_EXPERIENCES = gql`
  query ORDER_EXPERIENCES($orderCode: String!) {
    order(orderCode: $orderCode) {
        code
        invoiceDate
        deliveryTime
        status {
          status
        }
        orderExperiences {
          source
          rating
          comment
          metaTags {
            id
            title
          }
        }
      }
    }
`;

