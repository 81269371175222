import {
  SUBMIT_ORDER_EXPERIENCE,
  ORDER_EXPERIENCE_RATINGS,
  ORDER_EXPERIENCES,
} from '../../../graphql/orderExperience';

import { client } from "../../utilities/config";

export const submitOrderExperience = (rating, comment, orderCode, source, metaTagIds = []) => {
  return client.mutate(
    {
      mutation: SUBMIT_ORDER_EXPERIENCE,
      variables: {
        orderCode,
        rating,
        comment,
        source,
        metaTagIds,
      }
    }
  );
};

export const getOrderExperienceRatings = (orderExperienceSource, orderCode) => {
  return client.query(
    {
      query: ORDER_EXPERIENCE_RATINGS,
      variables: {
        orderExperienceSource,
        orderCode,
      }
    }
  );
}

export const getOrderExperiences = (orderCode) => {
  return client.query(
    {
      query: ORDER_EXPERIENCES,
      variables: {
        orderCode,
      }
    }
  );
}
