/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TagSelector.module.css';

class TagSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
    }
  }

  componentDidMount() {
    const { selected } = this.props;
    this.setState({
      selected,
    });
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    if (prevProps.selected.sort((a, b) => a - b) !== selected.sort((a, b) => a - b)) {
      this.setState({
        selected,
      });
    }
  }

  toggleMetaTag = (id) => {
    const { selected } = this.state;
    const { onChange } = this.props;
    const index = selected.indexOf(id);
    if (index === -1) {
      selected.push(id);
    } else {
      selected.splice(index, 1);
    }
    this.setState({
      selected,
    });
    onChange(selected);
  }

  renderMetatags = (metatags) => {
    const { selected } = this.state;
    return metatags.map((metatag) => {
      return (
        <div role="button" key={metatag.id} onClick={() => this.toggleMetaTag(+metatag.id)} className={[
          styles.tagBlock,
          'tagBlock text-dark dark:text-white',
          selected.indexOf(+metatag.id) !== -1 ? 'selected' : '',
        ].join(' ')}>
          { metatag.title }
        </div>
      );
    });
  }


  render() {
    const { className, metatags } = this.props;
    return metatags.length > 0 ? (
      <div className={[styles.tagSelectorContainer, className, 'tagSelectorContainer'].join(' ')}>
        { this.renderMetatags(metatags) }
      </div>
    ) : null;
  }
}

TagSelector.propTypes = {
  metatags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
  })),
  selected: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

TagSelector.defaultProps = {
  selected: [],
  onChange: () => {},
  className: '',
  metatags: [],
};

export default TagSelector;
