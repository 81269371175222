/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './OrderExperience.module.css';
import SmileRating from './SmileRating';
import TagSelector from './TagSelector';
import { getOrderExperienceRatings, submitOrderExperience } from '../../shared/repos/graphql/orderExperience';
import Loader from '../Loader/Loader';

class SmileRatingWidget extends Component {
  orderExperienceRatings = [];

  constructor(props) {
    super(props);

    this.state = {
      rating: null,
      metaTagIds: [],
      comment: '',
      showContent: false,
      processing: false,
      validationError: null,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.setState({
      showContent: false,
    });
  }

  init = async () => {
    const { defaultData, source, orderCode } = this.props;
    const res = await getOrderExperienceRatings(source, orderCode);
    this.orderExperienceRatings = res.data.orderExperienceRatings;
    this.setState({
      validationError: null,
      rating: defaultData?.rating || null,
      comment: defaultData?.comment || '',
      metaTagIds: defaultData?.metaTags?.map((metaTag) => +metaTag.id) || [],
      processing: false,
      showContent: true,
    });
  }

  getAvailableMetaTags = () => {
    const { rating } = this.state;
    return this.orderExperienceRatings.find((ratingData) => ratingData.rating === rating)?.metaTags || [];
  }

  setRating = (rating) => {
    const { metaTagIds } = this.state;
    const selectedmetatags = metaTagIds.filter(value => this.getAvailableMetaTags().map((metaTag) => +metaTag.id).includes(value));
    this.setState({
      validationError: null,
      metaTagIds: selectedmetatags,
      rating,
    });
  }

  setMetaTags = (metaTagIds) => {
    this.setState({
      metaTagIds,
    });
  }

  setComment = (event) => {
    this.setState({
      comment: event.target.value,
    });
  }

  submit = async () => {
    const { rating, metaTagIds, comment, processing } = this.state;
    const { onSubmited, orderCode, source } = this.props;
    if (rating === null) {
      this.setState({
        validationError: 'Please rate your experience.',
      });
      return;
    }
    if (processing === false) {
      this.setState({
        processing: true,
        validationError: null,
      });
      try {
        const selectedmetatags = metaTagIds.filter(value => this.getAvailableMetaTags().map((metaTag) => +metaTag.id).includes(value));
        await submitOrderExperience(rating, comment, orderCode, source, selectedmetatags);
        this.setState({
          processing: false,
        });
        onSubmited({
          rating,
          metaTags: selectedmetatags.map((id) => ({ id })),
          comment,
          source,
        });
      } catch (e) {
        const { graphQLErrors } = e;
        let error = 'Something went wrong. Please try again later.';
        if (graphQLErrors) {
          error = graphQLErrors[0].message;
        }
        this.setState({
          processing: false,
          validationError: error,
        });
      }
      
    }
  }

  renderValidationErrorMessage = () => {
    const { validationError } = this.state;
    return validationError ? (
      <div className={styles.errorMessage}>{validationError}</div>
    ) : null;
  }

  render() {
    const { className } = this.props;
    const { rating, showContent, metaTagIds, comment } = this.state;
    const metatags = this.getAvailableMetaTags();

    return showContent ? (
      <div className={[styles.smileRatingWidgetContainer, className, `dark:bg-dark`].join(' ')}>
        <SmileRating rating={rating} onRatingChange={this.setRating} />
        <TagSelector className={styles.tagSelector} selected={metaTagIds} metatags={metatags} onChange={this.setMetaTags} />
        <div className={styles.commentInputBlock}>
          <label className={'text-dark dark:text-white'}>Tell us a bit more (Optional)</label>
          <textarea
            className={[styles.commentInput, 'dark:text-dark'].join(' ')}
            placeholder="Enter message"
            onInput={this.setComment}
            defaultValue={comment}
          />
        </div>
        { this.renderValidationErrorMessage() }
        <button
          type="button"
          className={`bg-dark dark:bg-button text-white py-4 flex items-center justify-center ${styles.submitButton}`}
          onClick={ this.submit }
        >Submit</button>
      </div>
    ) : (<div className={styles.loaderContainer}><Loader /></div>);
  }
}

SmileRatingWidget.propTypes = {
  orderCode: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  defaultData: PropTypes.oneOfType([
    PropTypes.shape({
      rating: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([null]),
      ]),
      metaTags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      })),
      comment: PropTypes.string,
    }),
    PropTypes.oneOf([null]),
  ]),
  onSubmited: PropTypes.func,
  className: PropTypes.string,
};

SmileRatingWidget.defaultProps = {
  onSubmited: () => {},
  className: '',
  defaultData: null,
};

export default SmileRatingWidget;
