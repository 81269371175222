import endpoints from "./endpoints";

export default [
  {
    name: "Feedback",
    title: "Customer Help"
  },
  {
    name: "Events/Corporate Sales",
    action: () => {
      window.location.href = endpoints.eventsPage;
    },
    title: "Events/Corporate Sales"
  },
  {
    name: "Real Estate",
    title: "Real Estate"
  },
  {
    name: "Vendor",
    title: "Vendor"
  },
  {
    name: "Press",
    title: "Press"
  },
  {
    name: "Donations",
    action: () => {
      window.location.href = endpoints.donationPage;
    },
    title: "Donations"
  },
  {
    name: "Merch",
    action: () => {
      window.location.href = endpoints.gearSite;
    },
    title: "Merch"
  }
];

export const customerHelpTypes = [
  {
    key: "customer_help_order_Inquiry",
    title: "Order Inquiry"
  },
  {
    key: "customer_help_website_app_feedback",
    title: "Website / App Feedback"
  },
  {
    key: "customer_help_giftcard_inquiry",
    title: "Gift Card Inquiry"
  },
  {
    key: "customer_help_tax_exempt_inquiry",
    title: "Tax Exempt Inquiry"
  },
  {
    key: "customer_help_something_else",
    title: "Something Else"
  }
];
