/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Barcode from 'react-barcode';
import { checkPath, routeCountryPath } from '../../shared/utilities/common';
import * as orderRepo from '../../shared/repos/graphql/order';
import * as orderActions from '../../redux/actions/order';
import * as userActions from '../../redux/actions/user'
import HeroLeft from './HeroLeft/HeroLeft';
import Button from '../../components/Button/Button';
import copy from 'copy-to-clipboard';

import {
  renderGiftHeroLeft,
  renderGiftHeroRight,
  renderGiftBottom,
  renderLoggedInConfirmation
} from './rendering';

import styles from './ConfirmOrder.module.css';
import { getBarcodeId } from "../../shared/utilities/renderings";
import { orderTypeIds, recipientMessageLength } from "../../shared/constants/order";
import log from '../../shared/utilities/datadog/log';
import OrderTrackingWidget
  from "../../components/OrderTrackingWidget/OrderTrackingWidget";
import Loader from '../../components/Loader/Loader';
import Link from '../../components/LinkWithCountry/LinkWithCountry';
import ShippingTrackerWidget from '../../components/ShippingTrackerWidget/ShippingTrackerWidget';

class ConfirmOrder extends Component {
  constructor(props) {
    super(props);

    const orderCode = this.props.match.params.id;
    const { order } = this.props;
    const orderRecipient = order ? order.recipient : null;

    this.state = {
      giftFirstName: orderRecipient ? orderRecipient.firstName : '',
      giftLastName: orderRecipient ? orderRecipient.lastName : '',
      giftPhone: orderRecipient ? orderRecipient.phone : '',
      giftMessage: order ? order.comment : '',
      giftMessageLabel: '',
      firstnameErr: '',
      lastnameErr: '',
      phoneErr: '',
      edit: false,
      isValidData: true,
      shareButtonLabel: 'Share Tracker',
      loading: true,
      scrollDone: false,
    };

    this.getConfirmedOrder(orderCode).then(() => {
      this.setState({ loading: false });
    });
  }

  async getConfirmedOrder(orderCode) {
    const { orderUpdated } = this.props;

    const response = await orderRepo.getConfirmedOrder(orderCode);
    const { order } = response.data;

    log.logInfo(
      "[ConfirmOrder][getConfirmedOrder] ",
      {
        "OrderCode": orderCode,
        "response": response.data,
      });

    const messageMaxLength = order.orderTypeId === orderTypeIds.shipping ? recipientMessageLength.shippingOrder : recipientMessageLength.anotherOrder;
    const label = `Type a message for the recipient ( max ${messageMaxLength} characters )`;

    if (order.recipient) {
      this.setState({
        giftFirstName: order.recipient.firstName || '',
        giftLastName: order.recipient.lastName || '',
        giftPhone: order.recipient.phone || '',
        giftMessage: order.comment || '',
        giftMessageLabel: order.comment.length > 1 ? '' : label,
        edit: true
      });
    }
    else {
      this.setState({ giftMessageLabel: label })
    }

    orderUpdated(order);
  };

  renderBarCode = () => {
    const { order } = this.props;
    const { customer } = order;
    const barcodeId = getBarcodeId(customer.loyalty.id);

    return (
      <div className={styles.barCodeContainer}>
        <div>
          <p>Scan barcode to use rewards</p>
          <Barcode
            fontSize={0}
            background="#fcf7ef"
            value={barcodeId}
          />
        </div>
      </div>
    );
  };

  onShareTrackerClick = () => {
    const { order } = this.props;
    const trackingURL = routeCountryPath(`/checkout/order-tracker/${order.trackingID}`);
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    const port = window.location.port;
    const url = `${protocol}//${hostname}${port ? `:${port}` : ''}${trackingURL}`;
    if (navigator.share) {
      navigator.share({
        title: 'Insomnia Cookies Order Tracker',
        text: 'Check out my order tracking link!',
        url: url,
      });
    } else if (navigator.clipboard) {
      navigator.clipboard.writeText(url);
       this.setState({ shareButtonLabel: 'Link copied to clipboard!' }, () => {
        setTimeout(() => this.setState({ shareButtonLabel: 'Share Tracker' }), 3000);
       });
    } else {
      copy(url, {
        format: 'text/plain',
        message: 'Copied to clipboard'
      });
      this.setState({ shareButtonLabel: 'Link copied to clipboard!' }, () => {
        setTimeout(() => this.setState({ shareButtonLabel: 'Share Tracker' }), 3000);
      });
    }
  };

  collectPointsText = () => {
    return (
      <div className={styles.loggedOutBottomContainer}>
        <p>
        Collect points for future orders and receive special offers.
          <span className={styles.learnMoreLinkBlock}>
            <Link to="/insomniarewards">Learn More</Link>
          </span>
        </p>
      </div>
    );
  }

  confirmOrderContainer = () => {
    const { order } = this.props; // eslint-disable-line
    const { shareButtonLabel, scrollDone } = this.state;

    if (scrollDone === false) {
      window.scrollTo({
        top: 100,
        behavior: 'smooth',
      });
      this.setState({ scrollDone: true });
    }

    const isDelivery = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.delivery : false;
    const isPickUp = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.pickup : false;
    const isShipping = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.shipping : false;

    const customer = order ? order.customer : null;
    const isGift = checkPath(this, '/gift');

    return (
      <div className={styles.pageWrapper}>
        <div className={styles.pageContainer}>
          { order && <input type="hidden" value={order.id} id="confirmOrderId" /> }
          <div className={styles.pageTop}>
            {isGift ? renderGiftHeroLeft(this) : <HeroLeft />}
            {isGift ? renderGiftHeroRight() : ''}
          </div>

          <div className={`${styles.pageBottom} max-w-screen-desktop mx-auto text-left`}>

            {isDelivery || isPickUp ? (
              <div className="mb-4">
                <OrderTrackingWidget order={order} />
                <div className="flex justify-center mb-4">
                  <Button
                    id="share-tracker-button"
                    handleSubmit={this.onShareTrackerClick}
                    customContainerStyles="bg-button font-filsonProMedium text-light rounded-[54px] inline-block"
                    label={shareButtonLabel}
                    disabled={!order}
                  />
                </div>
              </div>
            ) : null}

            {isShipping ? (
              <div className="mb-4">
                <ShippingTrackerWidget order={order} />
                <div className="flex justify-center mb-4">
                  <Button
                    id="share-tracker-button"
                    handleSubmit={this.onShareTrackerClick}
                    customContainerStyles="bg-button font-filsonProMedium text-light rounded-[54px] inline-block"
                    label={shareButtonLabel}
                    disabled={!order}
                  />
                </div>
              </div>
            ) : null}

            {isGift ? renderGiftBottom(this) : null}

            <div className={!isGift && customer ? styles.barCodeWrapper : null}>
              {!isGift && customer && !isShipping ? renderLoggedInConfirmation(this) : null}
              {/* {!isGift && customer ? this.renderBarCode() : null} */}
            </div>
            {!isGift && !customer && order ? this.collectPointsText() : null}

          </div>
        </div>
      </div>
    );
  };

  loader = () => {
    return (
      <div className={styles.loaderConfirmPage}>
        <Loader />
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    return  loading ? <this.loader /> : <this.confirmOrderContainer />;
  }
}

ConfirmOrder.propTypes = {
  orderUpdated: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setAlertRecipientPhoneUpdated: PropTypes.func.isRequired
};

export const mapStateToProps = state => {
  return {
    order: state.order,
    alertRecipientPhone: state.user.alertRecipientPhone
  };
};

export const mapDispatchToProps = (dispatch) => ({
  orderUpdated: (value) => dispatch(orderActions.orderUpdated(value)),
  setAlertRecipientPhoneUpdated: (value) => dispatch(userActions.setAlertRecipientPhone(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOrder);
