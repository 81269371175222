import React, { Component } from "react";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import styles from "./Contact.module.css";
import DropDown from "../../components/Dropdown/Dropdown";
import StoreField from "./StoreField/StoreField";
import PhoneInputWidget from "../../components/PhoneInputWidget/PhoneInputWidget";

import messages from "../../shared/constants/messages";
import { handleFormInput } from "../../shared/utilities/validations";
import { customerHelpTypes } from "../../shared/constants/contactTabs";

import * as contactRepo from "../../shared/repos/graphql/contact";
import * as siteSettingRepo from '../../shared/repos/graphql/siteSetting';

class CustomerHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      errorMessage: null,
      orderNumber: "",
      fullName: "",
      email: "",
      phone: "",
      phoneData: {},
      renderForm: null,
      formType: "Feedback",
      subject: null,
      file: null,
      siteSettings: []
    };
    this.activeIndexCheck = null;
  }

  componentDidMount = async() => {
    const customerHelpTypeKeys = customerHelpTypes.map(item => item.key);
    const response = await siteSettingRepo.getSiteSettings(customerHelpTypeKeys);

    if(response?.data?.siteSettings) {
      const settingsData = customerHelpTypes.filter(item => {
        const setting = response?.data?.siteSettings.find(i => (i.name === item.key && i.value === '1'))
        return !!setting
      });

      const siteSettings = settingsData.map(item => item.title);
      this.setState({siteSettings});
    }    
  }


  renderInput = (
    component,
    label,
    state,
    isTextArea,
    validationType = "string",
    inputMask = ""
  ) => {
    const text = component.state[state];

    return (
      <Input
        isTextArea={isTextArea}
        theme="dark"
        customContainerStyles={styles.inputWrapper}
        handleInput={e => handleFormInput(e, component, state, validationType)}
        placeholder=" "
        value={text}
        label={label}
        inputMask={inputMask}
      />
    );
  };

  renderTextArea = (component, label = 'Description...') => {
    const { comment } = component.state;
    return (
      <div>
        <Input
          isTextArea
          theme="dark"
          customContainerStyles={styles.inputWrapper}
          handleInput={e =>
            e.target.value.length <= 1000
              ? handleFormInput(e, component, "comment")
              : null}
          placeholder=" "
          value={comment}
          label={label}
        />
        <span>{`${comment.length}/1000 Characters`}</span>
      </div>
    );
  };

  onUploadFile = ({target: { files }}) => {
    const file = files[0]
    this.setState({
      file
    })
  }

  renderFileInput = (title, name) => {
    return (
      <div className="mt-3 w-full">
        <label
          htmlFor="formFile"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {title}
        </label>
        <input
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          type="file"
          id={name}
          name={name}
          onChange={this.onUploadFile}
        />
      </div>
    );
  };

  renderDropDown = options => {
    const { subject } = this.state;
    return (
      <DropDown
        options={options}
        value={subject}
        customDropDownStyles={styles.feedback}
        onChange={e => this.onDropdownChange(e.value)}
      />
    );
  };

  onDropdownChange = subject => {
    let renderForm = null;
    switch (subject) {
      case "Order Inquiry":
        renderForm = this.renderOrderInqueryForm;
        break;
      case "Website / App Feedback":
        renderForm = this.renderAppFeedbackForm;
        break;
      case "Gift Card Inquiry":
        renderForm = this.renderGiftcardInqueryForm;
        break;
      case "Tax Exempt Inquiry":
        renderForm = this.renderTaxExemptInqueryForm;
        break;
      case "Something Else":
        renderForm = this.renderOtherInqueryForm;
        break;
      default:
        break;
    }
    this.setState({
      subject,
      renderForm
    });
  };

  renderOrderInqueryForm = () => {
    const { phoneErrorMessage, phone } = this.state;
    return (
      <div>
        {this.renderInput(this, "Name*", "fullName")}
        {this.renderInput(this, "Email*", "email", false, "email")}
        <div className={styles.phoneWidget}>
          <PhoneInputWidget
            customSelectStyles={styles.phoneSelectStyle}
            onInput={e => {
              this.setState({
                phone: e.telephone,
                phoneData: e
              });
            }}
            label="Mobile number"
            placeholder=" "
            errorMessage={phoneErrorMessage}
            telephone={phone}
            isNumberFormat
          />
        </div>
        {this.renderInput(this, "Order#*", "orderNumber")}

        <StoreField component={this} />
        {this.renderTextArea(this, 'Description*')}
        {this.renderFileInput('Upload', 'file')}
        <Button
          customContainerStyles={styles.submitButton}
          label="Submit"
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  };

  renderAppFeedbackForm = () => {
    const { phoneErrorMessage, phone } = this.state;
    return (
      <div>
        {this.renderInput(this, "Name*", "fullName")}
        {this.renderInput(this, "Email*", "email", false, "email")}
        <div className={styles.phoneWidget}>
          <PhoneInputWidget
            customSelectStyles={styles.phoneSelectStyle}
            onInput={e => {
              this.setState({
                phone: e.telephone,
                phoneData: e
              });
            }}
            label="Mobile number"
            placeholder=" "
            errorMessage={phoneErrorMessage}
            telephone={phone}
            isNumberFormat
          />
        </div>
        {this.renderInput(this, "Order#", "orderNumber")}

        <StoreField component={this} />
        
        {this.renderTextArea(this, 'Description*')}
        {this.renderFileInput('Upload', 'file')}
        <Button
          customContainerStyles={styles.submitButton}
          label="Submit"
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  };

  renderGiftcardInqueryForm = () => {
    const { phoneErrorMessage, phone } = this.state;
    return (
      <div>
        {this.renderInput(this, "Name*", "fullName")}
        {this.renderInput(this, "Email*", "email", false, "email")}
        <div className={styles.phoneWidget}>
          <PhoneInputWidget
            customSelectStyles={styles.phoneSelectStyle}
            onInput={e => {
              this.setState({
                phone: e.telephone,
                phoneData: e
              });
            }}
            label="Mobile number"
            placeholder=" "
            errorMessage={phoneErrorMessage}
            telephone={phone}
            isNumberFormat
          />
        </div>
        {this.renderInput(this, "Order#", "orderNumber")}
        {this.renderInput(this, "Giftcard#", "giftcardNumber")}
        {this.renderInput(this, "Giftcard Amount*", "giftcardAmount")}
        {this.renderInput(
          this,
          "Recipient's Email",
          "recipientEmail",
          false,
          "email"
        )}
        <StoreField component={this} />
        {this.renderTextArea(this, 'Description*')}
        {this.renderFileInput('Upload', 'file')}
        <Button
          customContainerStyles={styles.submitButton}
          label="Submit"
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  };

  renderTaxExemptInqueryForm = () => {
    const { phoneErrorMessage, phone } = this.state;
    return (
      <div>
        {this.renderInput(this, "Name*", "fullName")}
        {this.renderInput(this, "Email*", "email", false, "email")}
        {this.renderInput(
          this,
          "Rewards Account Email (if different)",
          "rewardAccountEmail"
        )}
        <div className={styles.phoneWidget}>
          <PhoneInputWidget
            customSelectStyles={styles.phoneSelectStyle}
            onInput={e => {
              this.setState({
                phone: e.telephone,
                phoneData: e
              });
            }}
            label="Mobile number"
            placeholder=" "
            errorMessage={phoneErrorMessage}
            telephone={phone}
            isNumberFormat
          />
        </div>
        {this.renderInput(this, "Order#", "orderNumber")}
        <StoreField component={this} />
        {this.renderTextArea(this, 'Description*')}
        {this.renderFileInput('Upload Tax Exempt Documents*', 'file')}
        <Button
          customContainerStyles={styles.submitButton}
          label="Submit"
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  };

  renderOtherInqueryForm = () => {
    const { phoneErrorMessage, phone } = this.state;
    return (
      <div>
        {this.renderInput(this, "Name*", "fullName")}
        {this.renderInput(this, "Email*", "email", false, "email")}
        <div className={styles.phoneWidget}>
          <PhoneInputWidget
            customSelectStyles={styles.phoneSelectStyle}
            onInput={e => {
              this.setState({
                phone: e.telephone,
                phoneData: e
              });
            }}
            label="Mobile number"
            placeholder=" "
            errorMessage={phoneErrorMessage}
            telephone={phone}
            isNumberFormat
          />
        </div>
        {this.renderInput(this, "Order#", "orderNumber")}
        <StoreField component={this} />
        {this.renderTextArea(this, 'Description*')}

        {this.renderFileInput('Upload', 'file')}
        <Button
          customContainerStyles={styles.submitButton}
          label="Submit"
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  };

  handleSubmit = () => {
    const {
      formType,
      orderNumber,
      fullName,
      email,
      phone,
      store,
      comment,
      organizationName,
      eventLocation,
      eventDate,
      eventType,
      proposedLocation,
      productName,
      productCost,
      phoneData,
      subject,
      giftcardNumber,
      giftcardAmount,
      recipientEmail,
      rewardAccountEmail,
      file
    } = this.state;
    this.setState({ errorMessage: null});
    let formValidated = false;
    switch (subject) {
      case "Order Inquiry":
        formValidated = this.validateOrderInquiryForm();
        break;
      case "Website / App Feedback":
        formValidated = this.validateAppFeedbackForm();
        break;
      case "Gift Card Inquiry":
        formValidated = this.validateGiftcardForm();
        break;
      case "Tax Exempt Inquiry":
        formValidated = this.validateTaxExemptInqueryForm();
        break;
      case "Something Else":
        formValidated = this.validateOtherInqueryForm();
        break;
      default:
        break;
    }

    if (phoneData.phone && phoneData.maskValidation === false) {
      window.scrollTo(0, 0); // eslint-disable-line
      return this.setState({
        errorMessage: messages.phoneInput.maskValidation.replace(
          "%s",
          phoneData.mask
        )
      });
    }

    if (formValidated) {
      return contactRepo
        .submitContactForm({
          formType,
          orderNumber,
          fullName,
          email,
          phone,
          store,
          comment,
          organizationName,
          eventLocation,
          eventDate,
          eventType,
          proposedLocation,
          productName,
          productCost,
          subject,
          giftcardNumber,
          giftcardAmount,
          recipientEmail,
          rewardAccountEmail,
          file,
        })
        .then(() => {
          window.scrollTo(0, 0); // eslint-disable-line
          this.setState({ successMessage: "Form successfully submitted!" }); // eslint-disable-line
          setTimeout(() => this.setState({ successMessage: false }), 2500); // eslint-disable-line
          this.resetFormFields();
        })
        .catch(error => {
          const { message } = error.graphQLErrors[0];
          window.scrollTo(0, 0); // eslint-disable-line
          this.setState({ errorMessage: message }); // eslint-disable-line
        });
    }

    return formValidated;
  };

  resetFormFields = () => {
    this.setState(
        {
          orderNumber: '',
          fullName: '',
          email: '',
          phone: '',
          store: '',
          comment: '',
          organizationName: '',
          eventLocation: '',
          eventDate: '',
          eventType: '',
          proposedLocation: '',
          productName: '',
          productCost: '',
          phoneData: '',
          subject: '',
          giftcardNumber: '',
          giftcardAmount: '',
          recipientEmail: '',
          rewardAccountEmail: '',
          errorMessage: null,
          file:null,
        }
    );
    this.onDropdownChange();
  }

  validateOrderInquiryForm = () => {
    const {
      fullName,
      email,
      orderNumber,
      comment,
      phoneData,
      file,
      store
    } = this.state;

    if (!email || !fullName || !orderNumber || !comment) {
      window.scrollTo(0, 0); // eslint-disable-line
      this.setState({ errorMessage: "Please fill out all necessary fields." }); // eslint-disable-line
      return false;
    }

    return {
      fullName,
      email,
      phoneData,
      orderNumber,
      store,
      comment,
      file
    };
  };

  validateAppFeedbackForm = () => {
    const {
      fullName,
      email,
      comment
    } = this.state;

    if (
      !email ||
      !fullName ||
      !comment
    ) {
      window.scrollTo(0, 0); // eslint-disable-line
      this.setState({ errorMessage: "Please fill out all necessary fields." }); // eslint-disable-line
      return false;
    }

    return true;
  };

  validateGiftcardForm = () => {
    const {
      fullName,
      email,
      giftcardNumber,
      giftcardAmount,
      comment
    } = this.state;

    if (!email || !fullName || !giftcardNumber || !giftcardAmount || !comment) {
      window.scrollTo(0, 0); // eslint-disable-line
      this.setState({ errorMessage: "Please fill out all necessary fields." }); // eslint-disable-line
      return false;
    }

    return true;
  };

  validateTaxExemptInqueryForm = () => {
    const { fullName, email, comment, file } = this.state;

    if (!email || !fullName || !comment) {
      window.scrollTo(0, 0); // eslint-disable-line
      this.setState({ errorMessage: "Please fill out all necessary fields." }); // eslint-disable-line
      return false;
    }

    if (!file) {
      window.scrollTo(0, 0); // eslint-disable-line
      this.setState({ errorMessage: "Please attached the tax exempt documents" }); // eslint-disable-line
      return false;
    }

    return true;
  };

  validateOtherInqueryForm = () => {
    const { fullName, email, comment } = this.state;

    if (!email || !fullName || !comment) {
      window.scrollTo(0, 0); // eslint-disable-line
      this.setState({ errorMessage: "Please fill out all necessary fields." }); // eslint-disable-line
      return false;
    }

    return true;
  };

  render() {
    const { errorMessage, successMessage, renderForm, siteSettings } = this.state;

    return (
      <div className={styles.formContainer}>
        <p>Customer Help</p>
        <span
          className={[
            errorMessage ? styles.error : null,
            successMessage ? styles.success : null
          ].join(" ")}
        >
          {errorMessage || successMessage}
        </span>
        <br />
        

        {this.renderDropDown(siteSettings)}

        {renderForm && renderForm()}
      </div>
    );
  }
}

export default CustomerHelp;
