/* eslint-disable react/forbid-prop-types */
import React, {Component} from 'react';
import styles from './ShippingTrackerWidget.module.css';
import MapComponent from '../MapComponent/MapComponent';
import moment from 'moment';
import PropTypes from 'prop-types';

class ShippingTrackerWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  trackingTime = time => {
    const datetime = moment(time).format('dddd MMM Do');
    return datetime === 'Invalid date' ? 'TBD' : datetime;
  }

  shippingDate = () => {
    const { order } = this.props;
    if (order?.shipment?.estimatedDeliveryDate === "-0001-11-30") { return "Delivered" }
    const date = moment(order?.shipment?.estimatedDeliveryDate).format('dddd MMM Do');
    return date === 'Invalid date' ? 'TBD' : date;
  }

  getShippingAddress = () => {
    const { order } = this.props;
    const addressParts = [
      order.shipment.shippingLocation?.addressOne,
      order.shipment.shippingLocation?.addressTwo,
      order.shipment.shippingLocation?.city,
      order.shipment.shippingLocation?.state,
      order.shipment.shippingLocation?.zip,
    ];
    const notEmptyParts = addressParts.filter(part => part);
    return notEmptyParts.join(', ');
  }

  render() {
    const { order } = this.props;
    const shippingStoreAddress = {
      lat: order.shipment?.shippingLocation?.lat,
      lng: order.shipment?.shippingLocation?.lng
    }
    const customerAddress = {
      lat: order.shippingAddress.lat,
      lng: order.shippingAddress.lng
    };
    const shipTo = this.getShippingAddress();
    return (
      <div className={[
            styles.componentWrapper,
            "p-[20px] rounded-[20px] border-[1px] border-lightDry dark:border-darkBorder",
            "bg-white dark:bg-dark font-filsonProRegular text-dark dark:text-white"].join(' ')}>
        <div className={styles.componentTitle}>Shipment tracking for order #{order.id}</div>

        <div className={styles.shipTo}>Shipping to: <span>{ shipTo }</span></div>

        <div className={styles.mainInfo}>
            <div>Tracking Number: <span>{order.shipment.trackingNumber}</span></div>
            <div>Delivery Method: <span>{order.shipment.shippingMethod}</span></div>
            <div>Customer Name: <span>{`${order.shippingFirstName} ${order.shippingLastName}`}</span></div>
        </div>

        <div className={styles.shipTo}>ETA: <span>{ this.shippingDate() }</span></div>

        <div className={styles.trackingStatus}>Tracking Status</div>
        <div className={styles.trackingContainer}>
          <div className={styles.trackingRow}>
            <p>Location</p>
            {order.shipment.tracking.map(item => {
              return (<p>{item.location ? item.location : '\u00A0'}</p>)
            })}
          </div>

          <div className={styles.trackingRow}>
            <p>Status</p>
            {order.shipment.tracking.map(item => {
              return (<p>{item.description ? item.description : '\u00A0'}</p>)
            })}
          </div>

          <div className={styles.trackingRow}>
            <p>Date/Time</p>
            {order.shipment.tracking.map(item => {
              return (<p>{item.time ? this.trackingTime(item.time) : '\u00A0'}</p>)
            })}
          </div>
        </div>
        <div className={styles.mapContainer}>
          <MapComponent
            storeAddress={shippingStoreAddress}
            customerAddress={customerAddress}
            myLocation={customerAddress}
            order={order}
          />
        </div>
      </div>
    );
  }
}

ShippingTrackerWidget.propTypes = {
  order: PropTypes.object.isRequired,
};

export default ShippingTrackerWidget;
